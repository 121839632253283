"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getReducer = exports.getBoundActions = exports.getPathBuilders = void 0;
const react_redux_1 = require("react-redux");
const symbols_1 = require("./symbols");
const getPathBuilders = (path) => ({
    actionPath: (action) => {
        const attachSuffix = (suffix) => (path.concat([suffix]).join("/"));
        if (typeof action === "symbol") {
            switch (action) {
                case symbols_1.setAction: {
                    return attachSuffix("overwrite:state");
                }
                case symbols_1.mergeAction: {
                    return attachSuffix("merge:state");
                }
                case symbols_1.resetAction: {
                    return attachSuffix("reset:state");
                }
                default: {
                    return "";
                }
            }
        }
        return attachSuffix(`action:${action}`);
    },
    updatePath: (key) => {
        return path.concat([`update:${key}`]).join("/");
    },
});
exports.getPathBuilders = getPathBuilders;
const getBoundActions = (state, selectorHandlers, actionHandlers, dispatch, getState, updatePath, actionPath) => {
    const selectors = Object.entries(selectorHandlers)
        .reduce((acc, [selectorKey, selectorHandler]) => (Object.assign(acc, {
        [selectorKey]: (input) => (selectorHandler(input, getState())),
    })), {});
    const useSelectors = Object.entries(selectorHandlers)
        .reduce((acc, [selectorKey, selectorHandler]) => (Object.assign(acc, {
        [selectorKey]: (input) => ((0, react_redux_1.useSelector)((state) => (selectorHandler(input, getState(state))))),
    })), {});
    const updaters = Object.keys(state)
        .reduce((acc, updateKey) => (Object.assign(acc, {
        [updateKey]: (input) => {
            dispatch({
                type: updatePath(updateKey),
                payload: input,
            });
        },
    })), {});
    const actions = Object.keys(actionHandlers)
        .reduce((acc, actionKey) => {
        return Object.assign(acc, {
            [actionKey]: (input) => {
                dispatch({
                    type: actionPath(actionKey),
                    payload: input,
                });
            },
        });
    }, {});
    return {
        selectors,
        useSelectors,
        updaters,
        actions,
    };
};
exports.getBoundActions = getBoundActions;
const getReducer = (defaultState, updateHandlers, actionHandlers, setActionSymbol, updatePath, actionPath) => {
    const updaters = Object.entries(updateHandlers)
        .reduce((acc, [updateKey, handler]) => (Object.assign(acc, {
        [updatePath(updateKey)]: handler,
    })), {});
    const actionKeys = (Object.keys(actionHandlers)
        .concat(Object.getOwnPropertySymbols(actionHandlers) || []));
    const actions = actionKeys
        .reduce((acc, actionKey) => (Object.assign(acc, {
        [actionPath(actionKey)]: actionHandlers[actionKey],
    })), {});
    return (state = defaultState, action) => {
        const handlers = [updaters, actions].find((handlerColl) => (handlerColl.hasOwnProperty(action.type)));
        if (!handlers)
            return state;
        const handler = handlers[action.type];
        const newState = handler(action.payload, state);
        if (newState === state)
            return state;
        const isOverwrite = action.type === actionPath(setActionSymbol);
        return isOverwrite ? Object.assign({}, newState) : Object.assign(Object.assign({}, state), newState);
    };
};
exports.getReducer = getReducer;
