"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createStore = exports.getRootCreators = exports.getCreators = exports.StateModel = void 0;
const model_1 = require("./model");
Object.defineProperty(exports, "StateModel", { enumerable: true, get: function () { return model_1.StateModel; } });
const store_1 = require("./store");
var util_1 = require("./util");
Object.defineProperty(exports, "getCreators", { enumerable: true, get: function () { return util_1.getCreators; } });
Object.defineProperty(exports, "getRootCreators", { enumerable: true, get: function () { return util_1.getRootCreators; } });
const createStore = (rootModels, extend) => {
    const store = new store_1.Store(rootModels, {
        enhancer: extend === null || extend === void 0 ? void 0 : extend.enhancer,
        asyncHandlers: extend === null || extend === void 0 ? void 0 : extend.asyncHandlers,
        selectors: extend === null || extend === void 0 ? void 0 : extend.selectors,
        events: extend === null || extend === void 0 ? void 0 : extend.events,
    });
    return store;
};
exports.createStore = createStore;
