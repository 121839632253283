"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a, _b, _c, _d;
Object.defineProperty(exports, "__esModule", { value: true });
exports.Store = void 0;
/* eslint-disable react-hooks/rules-of-hooks */
const react_1 = __importStar(require("react"));
const redux_1 = require("redux");
const react_redux_1 = require("react-redux");
const symbols_1 = require("../model/symbols");
const tools_1 = require("./tools");
const context_1 = require("./context");
const symbols_2 = require("./symbols");
class Store {
    constructor(models, options = {}) {
        this[_a] = {};
        this[_b] = {};
        this[_c] = {};
        this[_d] = {};
        this.Provider = ({ store, children }) => {
            const StoreProvider = context_1.context.Provider;
            return (react_1.default.createElement(StoreProvider, { value: store },
                react_1.default.createElement(react_redux_1.Provider, { store: store.redux }, children)));
        };
        this[symbols_2.storeEnhancer] = options === null || options === void 0 ? void 0 : options.enhancer;
        this[symbols_2.lifecycleEvents] = [];
        this.Provider = this.Provider.bind(this);
        this.attach = this.attach.bind(this);
        this.fireEvents = this.fireEvents.bind(this);
        this.instance = this.instance.bind(this);
        this.extend = this.extend.bind(this);
        this.extend(models, {
            asyncHandlers: options.asyncHandlers,
            selectors: options.selectors,
            events: options === null || options === void 0 ? void 0 : options.events,
        });
    }
    useStore() {
        const store = (0, react_1.useContext)(context_1.context);
        return store;
    }
    useModel() {
        const store = (0, react_1.useContext)(context_1.context);
        return store.model;
    }
    useState() {
        return (0, react_redux_1.useSelector)((state) => state);
    }
    useStoreInstance(preloadedState, options) {
        var _e;
        const curPreload = (0, react_1.useRef)(preloadedState);
        const curInstance = (0, react_1.useRef)();
        if (curPreload.current !== preloadedState ||
            !curInstance.current ||
            curInstance.current[symbols_2.storeInstanceCreated].valueOf() <= (this[symbols_2.storeExtended].valueOf())) {
            curPreload.current = preloadedState;
            curInstance.current = this.instance(preloadedState, Object.assign({ store: (_e = curInstance.current) === null || _e === void 0 ? void 0 : _e.redux, fireEvents: curInstance.current === undefined }, (options || {})));
        }
        return curInstance.current;
    }
    instance(newState = {}, options = {}) {
        const { store, fireEvents = false, } = options;
        const storeState = store
            ? Object.assign({}, this[symbols_2.defaultState], store ? store.getState() : {}, newState) : Object.assign(Object.assign({}, this[symbols_2.defaultState]), newState);
        const reduxStore = (0, redux_1.createStore)(this[symbols_2.rootReducer], storeState, this[symbols_2.storeEnhancer]);
        const models = Object.entries(this[symbols_2.modelRegistry])
            .reduce((acc, [modelName, model]) => (Object.assign(acc, {
            [modelName]: model[symbols_1.attachStore](reduxStore, [modelName]),
        })), {});
        const rootAsyncHandlers = this[symbols_2.asyncActionHandlers];
        const rootSelectorsHandlers = this[symbols_2.selectorHandlers];
        let asyncActions;
        let selectorActions;
        const storeInstance = {
            [symbols_2.storeInstanceCreated]: new Date(),
            redux: reduxStore,
            model: models,
            get select() {
                return selectorActions || (selectorActions = Object.entries(rootSelectorsHandlers)
                    .reduce((acc, [selectorName, handler]) => (Object.assign(acc, {
                    [selectorName]: (input) => (handler.apply(this, [
                        input,
                        models,
                    ])),
                })), {}));
            },
            get dispatchAsync() {
                return asyncActions || (asyncActions = Object.entries(rootAsyncHandlers)
                    .reduce((acc, [actionName, asyncHandler]) => (Object.assign(acc, {
                    [actionName]: (input) => __awaiter(this, void 0, void 0, function* () {
                        return (yield asyncHandler.apply(this, [
                            input,
                            models,
                        ]));
                    }),
                })), {}));
            },
            reset: () => {
                this[symbols_2.defaultState] = Object.entries(this[symbols_2.modelRegistry])
                    .reduce((acc, [key, model]) => (Object.assign(acc, {
                    [key]: (model.skipReset
                        ? models[model.name].state
                        : model.defaultState),
                })), {});
                reduxStore.dispatch({
                    type: symbols_2.rootReset,
                    payload: this[symbols_2.defaultState],
                });
                this.fireEvents("postReset", false);
            },
        };
        if (fireEvents) {
            this.fireEvents("postInit", true, storeInstance);
        }
        return storeInstance;
    }
    fireEvents(event, fireOnce, ...args) {
        this[symbols_2.lifecycleEvents].forEach(({ fired, events, }) => {
            const eventCb = events[event];
            const shouldFire = !fireOnce || !fired[event];
            if (!!eventCb && shouldFire) {
                // eslint-disable-next-line prefer-spread
                eventCb.apply(null, args);
                fired[event] = true;
            }
        });
    }
    extend(extendModels, options = {}) {
        const combined = [
            this[symbols_2.modelRegistry],
            extendModels,
        ].reduce(tools_1.combineModels, {
            models: {},
            defaultState: {},
        });
        this[symbols_2.modelRegistry] = combined.models;
        this[symbols_2.defaultState] = combined.defaultState;
        if (options.events) {
            this[symbols_2.lifecycleEvents].push({
                fired: Object.keys(options.events).reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: false })), {}),
                events: options.events,
            });
        }
        const reducer = (0, tools_1.getReducers)(this[symbols_2.modelRegistry]);
        this[symbols_2.rootReducer] = (state, action) => {
            switch (action.type) {
                case symbols_2.rootReset: {
                    return Object.assign({}, action.payload);
                }
            }
            return reducer(state, action);
        };
        const { asyncHandlers = {}, selectors: newSelectors = {}, } = options;
        Object.assign(this[symbols_2.asyncActionHandlers], asyncHandlers);
        Object.assign(this[symbols_2.selectorHandlers], newSelectors);
        this[symbols_2.storeExtended] = new Date();
    }
    attach(models, options) {
        this.extend(models, {
            asyncHandlers: options === null || options === void 0 ? void 0 : options.asyncHandlers,
            selectors: options === null || options === void 0 ? void 0 : options.selectors,
            events: options === null || options === void 0 ? void 0 : options.events,
        });
        return this;
    }
}
exports.Store = Store;
_a = symbols_2.defaultState, _b = symbols_2.modelRegistry, _c = symbols_2.asyncActionHandlers, _d = symbols_2.selectorHandlers;
