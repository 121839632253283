"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCreators = exports.getRootCreators = void 0;
const getRootCreators = (models) => ({
    createSelectors: (selectors) => (Object.entries(selectors)
        .reduce((acc, [key, handler]) => (Object.assign(acc, {
        [key]: handler.bind(selectors),
    })), {})),
    getAsyncActionCreator: (selectors) => (asyncHandlers) => Object.entries(asyncHandlers)
        .reduce((acc, [key, handler]) => (Object.assign(acc, {
        [key]: handler.bind(asyncHandlers),
    })), {}),
});
exports.getRootCreators = getRootCreators;
const getCreators = (state) => ({
    createUpdaters: (updaters) => updaters,
    createSelectors: (selectors) => selectors,
    createActions: (actions) => actions,
    getAsyncActionCreator: (actions, selectors) => ((asyncActions) => Object.entries(asyncActions)
        .reduce((acc, [key, handler]) => (Object.assign(acc, {
        [key]: handler.bind(asyncActions),
    })), {})),
});
exports.getCreators = getCreators;
