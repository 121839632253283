"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.combineModels = exports.getReducers = void 0;
const redux_1 = require("redux");
const symbols_1 = require("../model/symbols");
function getReducers(models) {
    const reducers = Object.entries(models)
        .reduce((reducerCollection, [modelName, model]) => {
        const attachMethod = model[symbols_1.reducer];
        const modelReducers = attachMethod([modelName]);
        return Object.assign(reducerCollection, {
            [modelName]: modelReducers,
        });
    }, {});
    return (0, redux_1.combineReducers)(reducers);
}
exports.getReducers = getReducers;
function combineModels(agg, models) {
    if (!models || typeof models === "boolean")
        return agg;
    return Object.values(models)
        .reduce((acc, model) => {
        if (acc.hasOwnProperty(model.name)) {
            const originalModel = acc.models[model.name];
            if (model.identifier !== originalModel.identifier) {
                throw new Error([
                    [
                        "Unable to bind state models to state.",
                        `Model '${model.name}' is a duplicate`,
                    ].join(" "),
                    `Path: ${Object.keys(acc).join(", ")}`,
                ].join("\n"));
            }
        }
        Object.assign(acc.models, {
            [model.name]: model,
        });
        Object.assign(acc.defaultState, {
            [model.name]: model.defaultState,
        });
        return acc;
    }, agg);
}
exports.combineModels = combineModels;
