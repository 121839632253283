"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getApi = void 0;
/* eslint-disable react-hooks/rules-of-hooks */
const react_redux_1 = require("react-redux");
const symbols_1 = require("./symbols");
const getApi = (path, selectors, useSelectors, updaters, actions, asyncActionHandlers, getState, dispatch, actionPath) => {
    let asyncActions;
    return {
        /**
         * Executing this is the same as reading the property
         * `StateModel.state`
         *
         * @returns the current state of the model
         */
        get() {
            return getState();
        },
        /**
         * Overwrite the entire state
         *
         * @param {TState} newState - The new state to set
         */
        set(newState) {
            dispatch({
                type: actionPath(symbols_1.setAction),
                payload: newState,
            });
        },
        /**
         * Merge a partial update of the state into the current value
         *
         * @param {Partial<TState>} newState - The partial chunk of state to merge
         * with the current.
         */
        merge(newState) {
            dispatch({
                type: actionPath(symbols_1.mergeAction),
                payload: newState,
            });
        },
        /**
         * Resets this model's state back to the default.
         */
        reset() {
            dispatch({
                type: actionPath(symbols_1.resetAction),
            });
        },
        /**
         * Returns reactive state or reactive state selectors.
         *
         * @remarks
         * - If `key` is defined, return the reactive state for that property
         * - If `key` is not defined, returns the collection of state selectors that
         *   were defined at model creation.  These will return reactive state.
         *
         * @param {string} key - What key to select
         * @returns Either the value of a state property (if `key` is defined), or the
         * selection of state selectors that were defined on model creation.
         */
        useSelector(key) {
            return (key
                ? (0, react_redux_1.useSelector)((state) => (getState(state, path.concat(key))))
                : useSelectors);
        },
        /**
         * Returns the complete state of this model, reactive
         *
         * @returns The complete (reactive) state of this model
         */
        useState() {
            return (0, react_redux_1.useSelector)((state) => getState(state));
        },
        /**
         * Returns the current state of the model.
         *
         * Performs the same function as `StateModel.get()`
         */
        get state() {
            return getState();
        },
        /**
         * @returns The collection of non-reactive state selectors that were
         * defined at model creation
         */
        get select() {
            return selectors;
        },
        /**
         * @returns The collection of actions that will update individual properties
         * on this model's state.
         */
        get update() {
            return updaters;
        },
        /**
         * @returns The collection of dispatch actions that were defined at
         * model creation
         */
        get dispatch() {
            return actions;
        },
        /**
         * @returns The collection of async action dispatchers that were defined
         * at model creation.
         */
        get dispatchAsync() {
            return asyncActions || (asyncActions = Object.entries(asyncActionHandlers)
                .reduce((acc, [actionKey, handler]) => (Object.assign(acc, {
                [actionKey]: (input) => __awaiter(this, void 0, void 0, function* () {
                    return (yield handler.apply(this, [
                        input,
                        this,
                    ]));
                }),
            })), {}));
        },
    };
};
exports.getApi = getApi;
