"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rootReset = exports.storeExtended = exports.storeInstanceCreated = exports.lifecycleEvents = exports.rootReducer = exports.selectorHandlers = exports.asyncActionHandlers = exports.modelRegistry = exports.storeEnhancer = exports.defaultState = void 0;
exports.defaultState = Symbol("default_state");
exports.storeEnhancer = Symbol("store_enhancer");
exports.modelRegistry = Symbol("model_registry");
exports.asyncActionHandlers = Symbol("async_actions");
exports.selectorHandlers = Symbol("selectors");
exports.rootReducer = Symbol("root_reducer");
exports.lifecycleEvents = Symbol("lifecycle_events");
exports.storeInstanceCreated = Symbol("instance_created");
exports.storeExtended = Symbol("registry_changed");
exports.rootReset = "root:reset:all";
